import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/menu-dafiti', title: 'Dafiti',  icon:'shopping_cart', class: '' },
    { path: '/menu-despachos', title: 'Despachos',  icon:'edit_calendar', class: '' },
    { path: '/user-profile', title: 'Rappi Exito',  icon:'accessibility_new', class: '' },
    { path: '/croydon-orders', title: 'Pedidos Croydon web',  icon: 'shopping_bag', class: '' },
    { path: '/cadenas', title: 'Cadenas',  icon:'offline_bolt', class: '' },
    { path: '/service-monioring', title: 'Monitorear servicios',  icon:'verified_user', class: '' },
    { path: '/inventory', title: 'Inventario',  icon:'build', class: '' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
